table {
    font-family: Inter, Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 90%;
  }
  
  table td, table th {
    border: 1px solid #ddd;
    /* padding: 8px; */
    width: 15px;
    max-width: 15px;
    /* background-color: #F6F8F8; */
    background-color: #ECF0F3;
    color: #4B566C;
  }
  
  /*table tr:nth-child(even){background-color: #f2f2f2;}*/
  
  /* table td:hover {background-color: #ddd;} */
  
  table th {
    /* padding-top: 12px; */
    padding-bottom: 12px;
    text-align: center;
    background-color: #1d3250;
    color: white;
  }