.wrapper {
  display: flex;
  height: 42rem;
  width: 90rem;
  margin: auto;
  max-width: 88rem;
  background: #ffffff;
  box-shadow: 0px 8px 20px rgb(0 0 0 / 25%);
  text-align: center;
  position: relative;
}

.dndflow {
  display: flex;
  align-items: stretch;
  background-color: #f3795e;
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  /* height: 100%; */
  height: 42rem;
  width: 100%;
}

.dndflow aside {
  border-right: 1px solid #eee;
  padding: 15px 10px;
  font-size: 12px;
  background: #fcfcfc;
}

.dndflow aside .description {
  text-align: center;
  margin-bottom: 10px;
}

.desc-header {
  margin: 2rem;
}

.dndflow .dndnode {
  height: 36px;
  padding: 4px;
  border: 2px solid #1a192b;
  border-radius: 2px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
  font-weight: bold;
}

.dndflow .dndnode.input {
  border-color: #0041d0;
}
.dndflow .dndnode.VPN {
  border-color: #e51212;
}
.dndflow .dndnode.SecurityGroup {
  border-color: #0041d0;
}
.dndflow .dndnode.Subnet {
  border-color: #1bb423;
}
.dndflow .dndnode.Instance {
  border-color: #ff0072;
}
.dndflow .dndnode.Router {
  border-color: #95d000;
}


.dndflow .reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
}

.dndflow .selectall {
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
  }

  .dndflow aside {
    width: 20%;
    max-width: 250px;
  }
}
