@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  /* background: #fff8f0; */
  background: #ECF0F3;
  font-family: 'Inter', sans-serif !important;
}

a {
  color: unset;
  text-decoration: none;
}

.kube__navbar-sign {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.sliderwrap {
  margin-top: 20px;
}

.sliderwrap label {
  font-size: 13px;
  font-weight: 100;
  margin-top: 15px;
  padding-bottom: 15px;
}

.labeltext {
  color: #212121;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
}

.screenshot {
  display: flex;
  width: 100%;
  height: 100%;
}

.c1 {
  width: 10%;
}

.c2 {
  flex-grow: 1;
}

.container {
  display: flex;
  flex-direction: column;
}

.kube__slider-wrap {
  box-sizing: border-box;
  height: 500px;
  margin: 0 auto;
  padding: 30px 10px;
  width: 60px;
}

.SelectedWeekExample {
  z-index: 500;
  width: max-content;
  height: max-content;
  position: absolute;
}

#slider .e-handle {
  height: 10px;
  width: 10px;
  /* margin-left: -10px;
  top: calc(50% - 10px); */
}

#slider .e-range {
  height: 60px;
  /* top: calc(50% - 3px); */
  border-radius: 5px;
}

#slider .e-slider-track {
  height: 8px;
  /* top: calc(50% - 4px); */
  border-radius: 5px;
}

.e-control-wrapper.e-slider-container .e-slider .e-range {
  background-color: #f27a5e;
}

.e-control-wrapper.e-slider-container .e-slider-button {
  background-color: #f27a5e;
}

.e-control-wrapper.e-slider-container .e-scale .e-tick .e-tick-value {
  color: #212121;
}

.e-control-wrapper.e-slider-container.e-vertical .e-slider {
  background-color: rgba(201, 199, 191, 0.951);
  left: 50%;
  position: relative;
  width: 2px;
}

.e-control-wrapper.e-slider-container.e-material-slider .e-slider .e-handle.e-handle-first,
.e-control-wrapper.e-slider-container .e-slider .e-handle {
  background: #f27a5e;
  border-color: #f27a5e;
  height: 600px;
  box-shadow: -2px 0 2px rgba(0, 0, 0, 0.25);
}

.e-slider-tooltip.e-tooltip-wrap.e-popup {
  background-color: #212121;
}

.e-slider-tooltip.e-tooltip-wrap.e-popup .e-arrow-tip-inner {
  color: #212121;
}

.e-slider-tooltip.e-tooltip-wrap.e-popup .e-arrow-tip-outer.e-tip-bottom {
  border-top-color: #212121;
}

.kube__dashboard {
  display: flex;
  flex-direction: row;
}

.kube__upper-tiles {
  margin-top: 6rem;
  flex: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3.125rem;
}

.kube_lower-tiles {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3.125rem;
}

.kube__date-range {
  margin-left: 90px;
  margin-right: 90px;
  padding-top: 15px;
  padding-bottom: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.kube__tile-container-button {
  cursor: pointer;
  border: none;
  background: none;
}

.kube__tile-container-button:hover {
  opacity: 0.5;
}

.kube__tile-screenshot {
  height: 309px;
  width: 510px;
}

.kube__tile-app,
.kube__back-button {
  position: absolute;
  top: 30px;
  left: 30px;
  cursor: pointer;
  background-color: #f3795e;
  color: #fff8f0;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 1.875rem;
  height: 1.875rem;
  cursor: pointer;
  filter: none;
  border: none;
}

.kube__back-button:hover {
  background-color: #e24623;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.login__back-button {
  cursor: pointer;
  background-color: #f3795e;
  color: #fff8f0;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 1.875rem;
  height: 1.875rem;
  filter: none;
  border: none;
}

.login__back-button:hover {
  background-color: #e24623;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.userselect__grid-button,
.userselect__list-button {
  filter: drop-shadow(0px 4px 0px rgba(0, 0, 0, 0.3));
  cursor: pointer;
}

.userselect__list-button {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 2.875rem;
  height: 2.875rem;
  left: 40%;
  cursor: pointer;
  filter: none;
  border: none;
  background: #ECF0F3;
  box-shadow: -8px -8px 16px #FFFFFF, 8px 8px 16px #D1D9E6;
  border-radius: 10px;
  border: none;
  color: #4B566C;
}

.userselect__grid-button {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 2.875rem;
  height: 2.875rem;
  left: 45%;
  cursor: pointer;
  filter: none;
  border: none;
  background: #ECF0F3;
  box-shadow: -8px -8px 16px #FFFFFF, 8px 8px 16px #D1D9E6;
  border-radius: 10px;
  border: none;
  color: #4B566C;
}

.kube__app-time {
  width: 200px;
  height: 40px;
  margin-top: 30px;
  line-height: 18px;
  text-align: center;
  color: #10213a;
  height: 3rem;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  background: #ECF0F3;
  box-shadow: -8px -8px 16px #FFFFFF, 8px 8px 16px #D1D9E6;
  border-radius: 10px;
  border: none;
  outline: none;
  color: #4B566C;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
}

.kube__tile-container {
  height: 21.275rem;
  width: 33.75rem;
  background: #ECF0F3;
  position: relative;
}

.kube__tile-title {
  font-family: var(--font-family);
  font-size: 0.875rem;
  margin-bottom: 0.625rem;
  padding-top: 10px;
  text-align: start;
  color: #212121;
}

.kube__bar-chart {
  width: 450px;
  margin: 0 auto;
}

.kube__doughnut-chart {
  width: 450px;
  height: 250px;
  margin: 0 auto;
  padding-top: 30px;
}

.kube__working-time {
  margin: 0% 20% 2% 20%;
  /* padding: 20px; */
  font-family: var(--font-family);
  font-weight: bold;
  font-size: 35px;
  color: #212121;
  cursor: pointer;
  background-color: #f27a5e;
  border-radius: 12px;
  text-align: center;
}

.kube__working-time:hover {
  background: rgba(242, 122, 94, 0.5);
}

.MuiOutlinedInput-root {
  /* border-radius: 0 !important;  */
  background-color: rgba(243, 121, 94, 0.2);
  width: 15.625rem;
  height: 3.125rem;
}

.react-datepicker__input-container input {
  font-size: 12px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 9px;
  display: flex;
  align-items: flex-end;
  color: #212121;
  /* color: #fff8f0; */
  height: 28px;
  width: 170px;
  padding: 10px;
  background: #ffbcad;
  /* background: #F5522C; */
  border: 0.569231px solid #f27a5e;
  box-sizing: border-box;
}

.react-datepicker__triangle::after {
  border-bottom-color: #f27a5e !important;
}

.react-datepicker {
  background: #ffbcad !important;
  border: 0.569231px solid #f27a5e !important;
  font-family: Roboto !important;
  letter-spacing: 0.284615px !important;
  color: #212121 !important;
  border-radius: 0 !important;
}

.react-datepicker__day--selected {
  font-weight: normal !important;
}

.react-datepicker__header {
  background-color: unset !important;
  border-bottom: unset !important;
}

.react-datepicker__day-name,
.react-datepicker__current-month {
  font-weight: bold;
  color: #212121 !important;
  padding-bottom: 8px !important;
}

.kube__app-time-row {
  padding: 15px;
}

.MuiAvatar-root {
  width: 30px !important;
  height: 30px !important;
}

.MuiTypography-body1 {
  font-size: 12px !important;
  color: #212121 !important;
}

.MuiTypography-body2 {
  font-size: 10px !important;
  color: #a8b2d1 !important;
}

.MuiListItemAvatar-root {
  min-width: 0 !important;
}

.MuiListItemText-root {
  padding-left: 10px !important;
}

.MuiListItem-root {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

/* .MuiListItem-gutters {
  padding-left: 52px !important;
  padding-right: 0 !important;
} */

.MuiList-padding {
  padding-top: 0px !important;
  padding-bottom: 0 !important;
}

/* .MuiGrid-container {
  padding-left: 12px !important;
} */

.kube__doughnut-date {
  font-family: var(--font-family);
  top: 163px;
  left: 246px;
  position: absolute;
  font-size: 10px;
  letter-spacing: 0.05em;
  color: #212121;
}

.kube__doughnut-time {
  font-family: var(--font-family);
  position: absolute;
  top: 182px;
  left: 239px;
  font-size: 18px;
  letter-spacing: 0.05em;
  color: #212121;
}

.MuiAvatar-colorDefault {
  background-color: unset !important;
}

.kube__forgot-password-container {
  width: 23rem;
  height: 40rem;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 25%);
  text-align: center;
  margin-top: 3rem;
}

.kube__profile-container,
.kube__settings-container {
  width: 21.688rem;
  height: 35.438rem;
  text-align: center;
  padding: 1.2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ECF0F3;
  box-shadow: -18px -18px 30px #FFFFFF, 18px 18px 30px #D1D9E6;
  border-radius: 25px;
}

.settings-util {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.kube__downloads__buttons__container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.kube__downloads__button {
  background: #05332F;
  background: #f3795e;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  height: 2rem;
  color: white;
  padding: .4rem;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(-6px, -6px) scale(0.75) !important;
}

.MuiInputLabel-outlined.MuiInputLabel {
  margin: 0 !important;
  padding: 0 !important;
}

.MuiInputLabel-shrink {
  transform: translate(-6px, -6px) scale(0.75) !important;
}

.makeStyles-formControl-1 {
  margin-top: 50px !important;
  margin-left: 20px !important;
  margin-bottom: 45px !important;
  width: 213px !important;
  background: #c0cae9 !important;
  border-radius: 4.00541px !important;
}

.MuiFormLabel-root {
  /* font-family: var(--font-family) !important;
  font-size: 16px !important;
  line-height: 21px !important;
  color: #000000 !important;
  left: 16px !important;
  top: 10px !important; */
  margin-left: 1.25rem;
}

label+.MuiInput-formControl {
  margin-top: 40px !important;
}

/* .MuiInputBase-input {
  border-top: 1px solid #172a46 !important;
} */

.MuiSelect-select {
  padding-right: 0 !important;
}

.MuiSelect-select option {
  padding-bottom: 8px !important;
  padding-top: 8px !important;
  padding-left: 16px !important;
}

.MuiInputBase-input option {
  font-family: var(--font-family) !important;
  font-size: 12px !important;
  color: #0a192f !important;
}

.MuiFormGroup-row p {
  font-family: var(--font-family) !important;
  color: #212121 !important;
  margin-left: 20px !important;
  font-size: 14px;
}

.MuiFormLabel-root.Mui-focused {
  color: #000000 !important;
}

.MuiSelect-icon {
  display: none !important;
}

.MuiFilledInput-underline:before {
  border-bottom: none !important;
}

.MuiFilledInput-root {
  margin-left: 20px !important;
  width: 60px !important;
  height: 25px !important;
  margin-top: 0 !important;
  padding-top: 0 !important;
  background-color: #a8b2d1 !important;
  border-radius: 0 !important;
  border-top: 0 !important;
}

.MuiRadio-root {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

/* .MuiIconButton-colorPrimary {
  color: #c0cae9 !important;
} */

.kube__settings-row2 {
  padding-bottom: 30px;
}

.kube__settings-row3 {
  padding-bottom: 45px;
}

.kube__settings-row4 {
  padding-bottom: 30px;
}

.kube__settings-row5 {
  padding-bottom: 20px;
}

.MuiLink-underlineHover {
  font-family: var(--font-family);
  font-size: 10px;
  color: #000000 !important;
  text-decoration: underline !important;
  margin: 0 auto !important;
  margin-top: 1rem !important;
}

.MuiButton-contained {
  width: 250px;
  height: 50px;
  margin: 0 auto !important;
  background: #c0cae9 !important;
  box-shadow: 2px 2px 2px rgb(0 0 0 / 30%) !important;
  border-radius: 120px !important;
  font-family: var(--font-family) !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 28px !important;
  letter-spacing: 0.05em !important;
  color: #172a46 !important;
  text-transform: none !important;
}

.kube__confirm-settings {
  margin: 0 auto !important;
}

.MuiCheckbox-root,
.MuiRadio-root {
  color: #0a192f !important;
}

.MuiCheckbox-colorPrimary.Mui-checked,
.MuiRadio-colorPrimary.Mui-checked {
  color: #a8b2d1 !important;
}

.kube__settings-radio-group {
  padding-left: 20px;
}

.MuiInputBase-input option:checked {
  background-color: #0a192f !important;
  color: #ffffff !important;
}

.kube-landing-heading {
  margin-top: 113px;
  font-family: "Ubuntu";
  font-weight: 300;
  font-size: 21px;
  letter-spacing: 0.1em;
  color: #c0cae9;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
}

.kube-landing-kube {
  margin-top: 25px;
  font-family: Vampiro One;
  font-size: 54px;
  letter-spacing: 0.1em;
  color: #ffd64d;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
}

.MuiOutlinedInput-adornedEnd {
  padding-right: 0 !important;
}

/* 
.kube-landing-button {
  margin-top: 125px;
  cursor: pointer;
  width: 300px;
  height: 50px;
  font-family: var(--font-family);
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.05em;
  background: #172a46;
  border: 1px solid #c0cae9;
  box-sizing: border-box;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  border-radius: 120px;
  color: #c0cae9;
} */

/* .kube-login-button {
  margin-bottom: 21px;
  margin-top: 85px;
  cursor: pointer;
  width: 300px;
  height: 50px;
  font-family: var(--font-family);
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.05em;
  background: #c0cae9;
  border: unset;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  border-radius: 120px;
  color: #172a46;
} */

/* .MuiIconButton-root {
  color: #f3795e !important;
} */

/* .kube__signin-row3 {
  margin-top: 20px !important;
  margin-right: -115px !important;
} */

.kube__usercard-container {
  margin-top: 2.188rem;
  margin-left: 6.5rem;
}

table {
  font-family: var(--font-family);
  width: 67.313rem;
  margin: 0 auto;
  margin-top: 1.188rem;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));
}

table td,
table th {
  padding: 8px;
}

table td {
  font-family: var(--font-family);
  font-size: 14px;
  color: #212121;
  text-align: center;
}

table td img {
  width: 30px;
}

table tr:nth-child(odd) {
  background-color: #ffffff;
}

table tr:nth-child(even) {
  background-color: #ffffff;
}

table tr:hover {
  background: #fff8f0;
  /* box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.25); */
  cursor: pointer;
}

table th {
  color: #000000;
  background: rgba(255, 48, 0, 0.5);
  font-size: 14px;
  font-weight: 500;
}

.kube__userselect-pagination {
  text-align: center;
  margin-top: 1.25rem;
  display: flex;
  justify-content: center;
  gap: 5px;
}

.kube__userselect-pagination-details {
  font-family: var(--font-family);
  margin-left: 7px;
  margin-right: 7px;
  padding-top: 7px;
  padding-bottom: 9px;
  padding-left: 15px;
  padding-right: 15px;

  cursor: pointer;
  filter: none;
  border: none;
  background: #ECF0F3;
  box-shadow: -8px -8px 16px #FFFFFF, 8px 8px 16px #D1D9E6;
  border-radius: 10px;
  border: none;
  color: #4B566C;
}

.kube__userselect-pagination button,
select {
  height: 35px;
  width: 70px;
  cursor: pointer;
  filter: none;
  border: none;
  background: #ECF0F3;
  box-shadow: -8px -8px 16px #FFFFFF, 8px 8px 16px #D1D9E6;
  border-radius: 10px;
  border: none;
  color: #4B566C;
  font-family: Inter, sans-serif;
  font-weight: 500;
}

.kube__userselect-pagination button:hover {
  background-color: rgba(243, 121, 94, 0.1);
  cursor: unset;
}

.kube__userselect-pagination button:disabled {
  /* color: rgba(33, 33, 33, 0.5); */
  color: #b7bbc4;
  font-family: Inter, sans-serif;
  font-weight: 500;
  background-color: #ECF0F3;
  cursor: unset;
}

.kube__userselect-filter {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.05em;
  color: #c0cae9;
  margin-top: 60px;
  text-align: center;
}

.kube__userselect-filter input {
  text-align: center;
  width: 18.75rem;
  height: 1.75rem;
  border-radius: 10px;
  outline: none;
  border: none;
  background: #F6F8F8;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 50%);
  border-radius: 10px;
  padding: 5px;
  font-family: 'Inter';
}

.kube__userselect-pagination select {
  width: 5.25rem;
}

.MuiInput-underline:after {
  border-bottom: 2px solid #000000 !important;
}

.MuiInput-underline:before {
  border-bottom: 1px solid #000000 !important;
}

/* .MuiInputBase-input {
  color: #000000 !important;
  font-size: 14px !important;
} */

.MuiFilledInput-input {
  border-top: unset !important;
  padding: unset !important;
  color: #000000 !important;
}

.kube__settings-row2.MuiInputBase-input {
  color: #000000 !important;
  font-size: 14px !important;
}

.flex-container {
  font-family: var(--font-family);
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  /* padding-top: 6px;
  margin-left: 5px; */
}

.flex-container>div {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 25%);
  /* width: 18.75rem; */
  width: 16.75rem;
  grid-gap: 10px;
  margin-right: 2.188rem;
  margin-bottom: 2.188rem;
  /* height: 24.375rem; */
  height: 21.375rem;
  cursor: pointer;
  /* padding-top: 10px;
  padding-left: 10px; */
}

.flex-container>div:hover {
  background: #fff8f0;
  box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.25);
}

.flex-container>div>div {
  padding-bottom: 6px;
}

.kube__usercard-avatar {
  margin-top: 2.188rem;
  margin-bottom: 1.25rem;
  margin-left: 1.6rem;
  width: 4.688rem;
}

.kube__usercard-apps img {
  width: 20px;
  margin-right: 10px;
}

.kube__usercard-apps span {
  padding-right: 10px;
}

.kube__usercard-title {
  font-weight: bold;
}

.kube__app-container {
  /* margin-top: 10px; */
  padding: 44px;
  font-family: var(--font-family);
  font-size: 12px;
  display: flex;
  align-content: auto;
  flex-wrap: wrap;
  justify-content: center;
  height: 270px;
  flex-direction: column;
  text-align: center;
  overflow: hidden;
}

.kube__app-container>div {
  height: 30px;
  width: 120px;
  padding-left: 10%;
  display: flex;
  padding-top: 20px;
  margin-bottom: 30px;
}

.kube__app-container-info {
  text-align: start;
  margin-left: 10%;
}

.kube__app-container-name {
  font-size: 14px;
  color: #212121;
}

.kube__app-container-time {
  margin-top: 3px;
  font-size: 10px;
  font-weight: 300;
  color: #212121;
}

.kube__password-heading {
  padding-bottom: 100px;
  padding-top: 60px;
  color: #212121;
  font-weight: 500;
}

.paginationBttns {
  margin-top: 20px;
  font-size: 14px;
  height: 35px;
  list-style: none;
  display: flex;
  justify-content: center;
}

.paginationBttns a {
  background: #c0cae9;
  border: none;
  font-family: var(--font-family);
  color: #0a192f;
  padding: 10px;
  margin: 8px;
  cursor: pointer;
}

.paginationBttns a:hover {
  background-color: #7c95e1;
}

.paginationActive a {
  background-color: #7c95e1;
}

.paginationDisabled a {
  color: #414b5a;
  background-color: #949ebd;
  cursor: unset;
}

.paginationDisabled a:hover {
  color: #414b5a;
  background-color: #949ebd;
  cursor: unset;
}

.landing__top {
  width: 100%;
  height: auto;
}

.landing__left {
  width: 50%;
  margin-top: 7rem;
  margin-left: 2rem;
}

.landing__right {
  margin-left: 2rem;
  margin-right: 45rem;
  padding-top: 15px;
  padding-bottom: 10px;
  margin-top: 7rem;
}

.landing__datepicker {
  margin-left: 2rem;
  margin-right: -28rem;
  display: flex;
  flex-direction: row;
  margin-bottom: 5rem;
  padding-top: 15px;
  padding-bottom: 10px;
}

.landing__TimeWidget {
  font-family: var(--font-family);
  font-weight: bold;
  font-size: 35px;
  cursor: pointer;
  text-align: center;
  margin-left: 3%;
  transition: 0.3s;
  background: #ECF0F3;
  box-shadow: -8px -8px 16px #ffffff, 8px 8px 16px #d1d9e6;
  border-radius: 10px;
  border: none;
  color: #4B566C;
}

.landing__TimeWidget:hover {
  background: #F3795E;
  box-shadow: -8px -8px 16px #ffffff, 8px 8px 16px #d1d9e6;
  border-radius: 10px;
  border: none;
  color: #ECF0F3;
}

.landing__pieChart {
  display: flex;
  align-items: flex-start;
}

.sidenavbar__main {
  display: flex;
  flex-direction: row;
}

.Sidenavbar__sideBar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 210px;
  /* Set the width of the sidebar */
  background-color: #fff8f0;
  background-color: #fef2ef;
  overflow-x: hidden;
  /* Disable horizontal scroll */
  padding-top: 100px;
}

.sidenavbar__sideBar__main {
  border: 1px solid red;
  background-color: #f3795e;
  padding: 5px;
  color: #fff;
}

.sidenavbar__sidebar__button {
  font-size: 1.2rem;
  margin-top: 1rem;
  cursor: pointer;
  background-color: #fff8f0;
  border: none;
  padding: 5px;
}

.sidenavbar__sidebar__button:focus {
  background-color: #e63737;
}

.sidenavbar__sidebar__button:hover {
  background: #e24623;
}

.card-loader-dots {
  width: 55%;
  display: inline-flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-content: stretch;
}

.kube--util--center--div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loaderDots {
  display: flex;
  justify-content: center;
}

.dot-flashing {
  padding: 2px;
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #f3795e;
  color: #f3795e;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #f3795e;
  color: #f3795e;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #f3795e;
  color: #f3795e;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #f3795e;
  }

  50%,
  100% {
    background-color: #ebe6ff;
  }
}

.toggle-btn-container {
  display: flex;
  justify-content: center;
}

.toggle-btn-play {
  width: 34px;
  height: 34px;
  box-sizing: border-box;
  border-style: solid;
  border-width: 20px 0px 20px 30px;
  border-color: transparent transparent transparent #f3795e;
  transition: all 100ms ease-in-out;
}

.toggle-btn-pause {
  width: 25px;
  height: 34px;
  border-right: 10px solid #f3795e;
  border-left: 10px solid #f3795e;
}

.errorPresent {
  color: #e24623;
  text-align: left;
  margin-left: 2rem;
  margin-top: 5px;
  font-weight: bold;
}

.ui_message_success {
  color: rgb(38, 188, 222);
  text-align: center;
  font-weight: bold;
}

.refresh_option {
  margin-left: 1rem;
  margin-top: 5px;
}

.refresh_option:hover {
  transform: rotate(360deg) scale(1.2);
}

.orgStyle {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.doneButton {
  box-sizing: border-box;
  color: white;
  background-color: rgb(89, 146, 237);
  border-radius: 3px;
}

.buttons-container {
  display: flex;
  margin-top: 1rem;
}

/* .dataGridTable ul.MuiList-root.MuiMenu-list {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
} */
.MuiList-root.MuiList-padding.MuiMenu-list {
  display: flex;
  flex-direction: column;
}

.MuiList-root.MuiList-padding.MuiMenu-list>.MuiButtonBase-root {
  padding: 5px 10px;
  display: flex;
  justify-content: flex-start;
}

.network-container {
  display: flex;
  align-items: stretch;
}

.leave-wrapper {
  background: #ECF0F3;
  box-shadow: 0px 8px 20px rgb(0 0 0 / 25%);
  text-align: center;
  position: relative;
  display: flex;
  height: 100vh;
  width: 100vw;
  text-align: center;
  position: relative;
  overflow: auto;
}

.leave-dialog-box {
  padding: 1rem;
}

.leave-dialog-datepickers {
  display: flex;
  justify-content: space-between;
  gap: 5rem;
}

.user-apply-leave-button {
  margin: 1rem;
}

.kube--admintable--filter {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.05em;
  color: #c0cae9;
  /*  */
  /* margin: 2rem 0rem; */
  /*  */
  text-align: center;
}

.kube--admintable--filter input {
  text-align: center;
  width: 18.75rem;
  height: 1.75rem;
  border-radius: 10px;
  outline: none;
  border: none;
  background: #F6F8F8;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 50%);
  border-radius: 10px;
  padding: 5px;
  font-family: 'Inter';
}

.kube--holidaytable {
  margin: 3rem;
}

.admin-add-holiday-button {
  margin: 1rem;
  font-size: 17px;
  font-weight: bold;
  height: 2.5rem;
  width: 8rem;
  background-color: #f3795e;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  color: rgb(255, 255, 255);

}

.user-apply-leave-button {
  font-size: 16px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  height: 2.5rem;
  width: 9rem;
  border: none;
  cursor: pointer;
  background: #f3795e;
  color: #ECF0F3;
  border-radius: 10px;
}

.kube__holidaytable-pagination-details {
  font-family: var(--font-family);
  background-color: #ffffff;
  margin-left: 7px;
  margin-right: 7px;
  padding-top: 7px;
  padding-bottom: 9px;
  padding-left: 15px;
  padding-right: 15px;
}

.kube__holidaytable-pagination button {
  height: 35px;
  width: 70px;
  background: #ffffff;
  border: none;
  font-family: var(--font-family);
  color: #212121;
  cursor: pointer;
}

.kube__holidaytable-pagination button:hover {
  background-color: rgba(243, 121, 94, 0.1);
  cursor: unset;
}

.kube__holidaytable-pagination button:disabled {
  color: rgba(33, 33, 33, 0.5);
  background-color: #ffffff;
  cursor: unset;
}

.admin-add-holiday-dialog {
  padding: 1rem;
  min-width: 28rem;
}

.user-apply-leave-dialog {
  padding: 1rem;
  min-width: 20rem;
}

.overlay--card--close--button {
  height: 2rem;
  width: 2rem;
  height: auto;
  width: auto;
  margin-left: 64rem;
  margin-top: 0.1rem;
}

.MuiButtonBase-root MuiIconButton-root overlay--card--close--button {
  margin-left: 64rem;
}

.test-table-wrapper {
  position: absolute;
  top: 10rem;
  width: 100vw;
  height: calc(100vh - 8rem - 2px);
}

.monthly-compliance-datepickers {
  margin-left: 33rem;
  margin-right: 25rem;
  display: flex;
  padding-top: -1px;
  padding-bottom: 2rem;
}

.monthly-compliance-select {
  display: flex;
  justify-content: center;
  /* margin-top: -2rem; */
  margin-bottom: 2rem;
}

.weekly-compliance-dates {
  display: flex;
  justify-content: center;
}

.monthly--compliance--filter {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  margin-top: -6rem;
  margin-bottom: 1rem;
}

.sessionDetailsContainer {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  align-items: center;
}


/* SPECIFYING CSS classes instead of the styled divs for better styling*/

/* New Navbar styles */
.display-row {
  display: flex;
  height: 100vh;
  width: 100vw;
}

.new--navbar {
  display: flex;
  flex-direction: column;
  background: #ECF0F3;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 15%);
  gap: 1rem;
  /* padding: 0.55rem; */
  z-index: 1;
}

.new--navbar>.MuiIconButton-root {
  /* color: #fff8f0 !important; */
  color: #FF6C37 !important;
  font-weight: bold;
  border-radius: 10px !important;
  height: 2.5rem;
  width: 2.5rem;
  background: #ECF0F3;
  box-shadow: 4px 4px 8px #D1D9E6, -4px -4px 8px #FFFFFF;
  transition: 0.15s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new--navbar>.MuiIconButton-root:hover {
  /* color: #fff8f0 !important; */
  color: #ECF0F3 !important;
  font-weight: bold;
  background: #FF6C37;
  border-radius: 10px !important;
  /* background: #FF4D00; */
  box-shadow: 4px 4px 8px #D1D9E6, -4px -4px 8px #FFFFFF;
}


/* Hoverable menu in the navbar */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  /* The container <div> - needed to position the dropdown content */
  display: none;
  position: absolute;
  background-color: #ECF0F3;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: #4B566C;
  font-family: Inter, sans-serif;
  font-weight: 500;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* Hoverable menu in the navbar */




/* Kube login page styles */

.kube--login--wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 21.688rem;
  height: 35.438rem;
  background: #ECF0F3;
  box-shadow: -15px -15px 30px #ffffff, 15px 15px 30px #d1d9e6;
  border-radius: 25px;
}

.kube--login--inputs {
  height: 2rem;
  width: 80%;
  font-family: Inter;
  padding: 10px;
  outline: none;
  border: none;
  background: #ECF0F3;
  box-shadow: inset 5px 5px 10px #D1D9E6, inset -5px -5px 10px #FFFFFF;
  border-radius: 10px;
}

.kube--login--organization {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.kube-login-email {
  width: 410px;
  height: 48.13px;
  left: 659px;
  top: 598px;
  background: #F6F8F8;
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.kube--login--buttons {
  background: #F3795E;
  height: 2rem;
  width: 80%;
  font-family: Inter;
  padding: 10px;
  color: #F6F8F8;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  box-shadow: -18px -18px 30px #FFFFFF, 18px 18px 30px #D1D9E6;
  border-radius: 10px;
}

.kube--login--logo--container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3.65rem;
  gap: 1rem;
}

.kube--login--divider--comp {
  display: flex;
  flex-direction: column;
  width: 7rem;
  color: blue;
  background: #e8e8e8;
  margin-right: auto;
  margin-left: auto;
}

.kube--login--loginbutton--container {
  display: flex;
  justify-content: center;
}

/* Forgot Password Page Styles */
.visibility--pass--eye {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2rem;
  width: 80%;
  font-family: Inter;
  padding: 10px;
  outline: none;
  border: none;
  background: #ECF0F3;
  /* box-shadow: inset 5px 5px 10px #D1D9E6, inset -5px -5px 10px #FFFFFF; */
  box-shadow: inset 4px 4px 8px #D1D9E6, inset -4px -4px 8px #FFF;
  border-radius: 10px;
}

#kube--forgotpassword--password--input {
  height: 1.4rem;
  /* width: 80%; */
  font-family: Inter;
  /* padding: 10px; */
  outline: none;
  border: none;
  background: #ECF0F3;
  /* box-shadow: inset 5px 5px 10px #D1D9E6, inset -5px -5px 10px #FFFFFF; */
  border-radius: 10px;
}

/* Landing Icon (Page) Styles */
.datePicker {
  background: #F6F8F8;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
  height: 3rem;
  /* box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5); */
  /* border-radius: 10px; */
  text-align: center;
  /* color: #fff8f0; */
  /* background: #f5522c; */
  /* background: #F3795E; */
  font-size: .9rem;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  background: #ECF0F3;
  /* box-shadow: -8px -8px 16px #FFFFFF, 8px 8px 16px #D1D9E6; */
  box-shadow: -4px -4px 16px #FFF, 6px 6px 16px #D1D9E6;
  border-radius: 10px;
  border: none;
  color: #4B566C;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
}

.overlay-col-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  padding-bottom: 2rem;
}

.overlay--card--inner--wrappe {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.25s ease;
}

.overlay--card--card {
  width: 90rem;
  height: 28rem;
  width: 67rem;
  background-color: #FFF8F0;
  box-shadow: 0 4px 15px -5px black;
  transition: all 0.25s ease;
  text-align: center;
  border-radius: 2rem;
}


/* Styles for the modal on time click  */
.css-7lsesa {
  border-radius: 2rem;
  border-radius: 10px;
  background: #ECF0F3;
}

.css-7lsesa::-webkit-scrollbar {
  display: none;
}




/* Userselect Styles */
.userselect--top--level--wrapper {
  width: 100%;
  padding: 2rem;
  overflow: scroll;
}

.userselect--top--section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
}

.userselect--datepickers--wrapper {
  display: flex;
  flex-flow: row nowrap;
}

.usercard--userdetails--container {
  width: 16.75rem;
  height: 21.750rem;
  background: #ECF0F3 !important;
  box-shadow: -15px -15px 30px #FFFFFF, 15px 15px 30px #D1D9E6 !important;
  border-radius: 25px !important;
}

.kube--usercard--userdetails--section {
  display: flex;
  flex-direction: column;
  gap: .3rem;
  margin-top: 1rem;
}




/* Screenshot Gridview Styles */
.gridview--top--level--wrapper {
  width: 100%;
  overflow: scroll;
  overflow-x: auto;
  overflow-y: auto;
}

.gridview--left--section {
  display: flex;
  flex-direction: column;
  gap: 7rem;
  justify-content: center;
  align-items: center;
  width: 10%;
}

.gridview--table {
  overflow: auto;
  flex-grow: 1;
}

.gridview--left--section--buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.gridview--validation--button {
  background-color: #f3795e;
  color: #ECF0F3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 1.875rem;
  height: 1.875rem;
  width: 5.875rem;
  height: 2rem;
  top: 58px;
  left: 40%;
  cursor: pointer;
  filter: none;
  border: none;
  font-family: Inter;
  font-weight: bold;
}


.gridview--validation--button:hover {
  background-color: #e24623;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}



/* Access Management Styles */
.accessmanagement--top--level--wrapper {
  width: 100%;
  padding: 2rem;
  overflow: scroll;
}

/* accessmanagement -> users */
.all--users--toplevel--wrapper {
  height: 100vh;
  width: 100vw;
}


.all--users--content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.all--users--top--section {
  display: flex;
  gap: 2rem;
  justify-content: space-around;
  align-items: center;
  padding: 0 3rem;
  height: 6rem;
}

.kube--action--button {
  color: #F6F8F8;
  text-align: center;
  outline: none;
  border: none;
  background: #f3795e;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 50%);
  border-radius: 10px;
  font-family: 'Inter';
  font-weight: 500;
  padding: 7px 18px;
  cursor: pointer;
}


.all--users--datagrid {
  background: #ECF0F3;
  box-shadow: -8px -8px 16px #FFFFFF, 8px 8px 16px #D1D9E6;
  border-radius: 10px;
  height: 72vh;
  width: 70vw;
  margin-left: 3rem;
}

/* accessmanagement -> users --> workspace and EC2*/
.manage--user--workspace--top--section {
  width: 100vw;
  display: flex;
  justify-content: center;
  margin-top: 4rem;
}

.manage--user--workspace--add--wspc {
  height: 3rem;
  width: 10rem;
}

/* Shared Styles */
.kube--input--container {
  background: #F6F8F8;
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}


/* Leave management & compliance styles */

/* Tab indicator styles */
.css-1aquho2-MuiTabs-indicator {
  /* background-color: #f3795E !important; */
}


.admin--leavemanagement--content {
  display: flex;
  flex-direction: column;
}

.admin--leavemanagement--top--section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8rem;
  padding: 1rem;
}


.admin--leavemanagement--bottom--section {
  padding: 1rem;
}


.kube--weekly--userscompliance--wrapper {
  margin-top: 6rem;
}

.export--csv--button {
  font-family: Inter, sans-serif;
  font-size: 17px;
  font-weight: bold;
  background: #f3795e;
  border-radius: 10px;
  color: white;
  outline: none;
  cursor: pointer;
  border: none;
  margin: 1rem;
  height: 2.5rem;
  width: 8rem;
}

.week--change--button {
  height: 1.85rem;
  width: 15rem;
  border-radius: 10px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  font-family: Inter;
  border: none;
  background: #ECF0F3;
  box-shadow: -8px -8px 16px #ffffff, 8px 8px 16px #d1d9e6;
  border: none;
  color: #4B566C;
}


/* React table styling */

.table {
  border-radius: 10px;
  overflow: hidden;
  font-family: Inter, sans-serif !important;
}


/* IMP property */
.th {
  font-family: 'Inter', sans-serif !important;
  background: #ECF0F3;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  color: #8A92A3;
}




/* IMP property*/
.td {
  background: #ecf0f3;
  font-family: 'Inter', sans-serif !important;
  font-weight: 500;
  line-height: 19px;
  color: #4B566C;
}



/* Utility Classes (Especially for the box-shadow) */
.util--container {
  background: #ECF0F3;
  box-shadow: -15px -15px 30px #FFFFFF, 15px 15px 30px #D1D9E6;
  border-radius: 25px;
}

.util--input {
  background: #ECF0F3;
  box-shadow: inset 5px 5px 10px #D1D9E6, inset -5px -5px 10px #FFFFFF;
  border-radius: 10px;
}

.util--btn {
  background: #f3795e;
  color: #ECF0F3;
  border-radius: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
}

.util--dates {
  background: #ECF0F3;
  box-shadow: -8px -8px 16px #FFFFFF, 8px 8px 16px #D1D9E6;
  border-radius: 10px;
}

.kube--pagination--button {
  cursor: pointer;
  filter: none;
  border: none;
  background: #ECF0F3;
  box-shadow: -8px -8px 16px #FFFFFF, 8px 8px 16px #D1D9E6;
  border-radius: 10px;
  border: none;
  color: #4B566C;
}
#landingright-buttons{
  display: grid;
  grid-template-columns: repeat(2,50%);
  justify-content:center;
  justify-items: center;
}
#invalidatedslotsdiv{ 
  width: 70% ;
  height : 70%;
  margin:auto;
  position:relative;
  top:150px;
 }
#invalidatedslotsdiv:hover{
  border: 2px solid grey;
  border-radius:10px 10px;
  box-shadow: 5px 5px 10px #00A5EC ,-5px -5px 10px #00A5EC;

}


/* Global Scrollbar CSS */
::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-track {
  background-color: rgb(196, 196, 196);
}

::-webkit-scrollbar-thumb {
  background-color: rgb(143, 143, 143);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: grey;

  border-radius: 10px;
}

/* Global Scrollbar CSS */